import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { signinUser } from "./action";
import { AuthContext } from "../../context/auth";
import { useAlert } from "react-alert";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import ReCAPTCHA from "react-google-recaptcha";
import { Form } from "formik";

import {
  CPFInput,
  getCpfOnlyNumbers,
  isValidCPF,
} from "../generic/formElements/Cpf";
import { MyLoader } from "@lucasapereira/lib-react";

const useStyles = makeStyles((theme) => ({
  root: {
    //padding: theme.spacing(1, 1),
    justifyContent: "center",
    height: "93vh",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ededed",
  },
  box: {
    padding: "20px 10px 10px 10px ",
    width: "365px",
    borderRadius: "10px",
  },
}));

export const FormLogin = (props) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  const recaptchaRef = React.createRef();

  const {
    values: { cpf, password },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    isSubmitting,
    setSubmitting,
  } = props;

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const alert = useAlert();

  const onSubmit = async (event) => {
    event.preventDefault();
    let recaptchaValue = "";
    if (
      process.env.NODE_ENV === "production" ||
      process.env.NODE_ENV === "testing"
    ) {
      recaptchaValue = recaptchaRef.current.getValue();
    }
    setSubmitting(true);

    if (!isValidCPF(cpf)) {
      alert.error("CPF inválido");
      setSubmitting(false);
      return;
    }

    const ret = await signinUser(
      getCpfOnlyNumbers(cpf),
      password,
      auth.alteraAutenticacao,
      recaptchaValue
    );

    if (ret) {
      alert.error(ret.msg);
    }

    setSubmitting(false);
  };

  const getButton = () => {
    if (isSubmitting) {
      return <MyLoader />;
    } else {
      return (
        <Button
          type="submit"
          fullWidth
          color="primary"
          disabled={!isValid || isSubmitting}
        >
          Enviar
        </Button>
      );
    }
  };

  const getRecaptcha = () => {
    if (
      process.env.NODE_ENV === "production" ||
      process.env.NODE_ENV === "testing"
    ) {
      return (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        />
      );
    }
  };

  return (
    <Paper className={classes.root}>
      <Paper className={classes.box}>
        <Form onSubmit={onSubmit}>
          <TextField
            id="cpf"
            name="cpf"
            helperText={touched.cpf ? errors.cpf : ""}
            error={touched.cpf && Boolean(errors.cpf)}
            label="CPF"
            value={cpf}
            type="string"
            onChange={change.bind(null, "cpf")}
            fullWidth
            InputProps={{
              inputComponent: CPFInput,
            }}
          />

          <TextField
            id="password"
            name="password"
            helperText={touched.password ? errors.password : ""}
            error={touched.password && Boolean(errors.password)}
            label="Senha"
            placeholder="Digite a senha"
            fullWidth
            autoComplete="current-password"
            type={values.showPassword ? "text" : "password"}
            value={password}
            onChange={change.bind(null, "password")}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {getRecaptcha()}
          {getButton()}
        </Form>
        <br/>
           <a href="https://sistemas.cbm.df.gov.br/sistemas/cerberusWeb/public/senha/recuperar-senha">
             Esqueci minha senha
             </a>
      </Paper>

   
    </Paper>
  );
};
