import axios from "axios";
//import AlertContainer from "react-alert";
import { getStorage, setStorage } from "./components/generic/storage";

import { authOptions } from "./components/generic/myAxios";

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  /* eslint-disable */
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const sendSubscriptionToServer = async (endpoint, key, auth) => {
  const encodedKey = window.btoa(
    String.fromCharCode.apply(null, new Uint8Array(key))
  );
  const encodedAuth = window.btoa(
    String.fromCharCode.apply(null, new Uint8Array(auth))
  );

  try {
    const response = await axios.post(
      "/users/createWebPushToken",
      {
        cod_usuario: getStorage("cod_usuario"),
        publicKey: encodedKey,
        auth: encodedAuth,
        notificationEndPoint: endpoint,
      },
      authOptions()
    );

    if (response.data.success === true) {
      setStorage("webpushtoken", true, 60 * 60 * 24);
    } else {
      setStorage("webpushtoken", false);
    }
  } catch (e) {
    setStorage("webpushtoken", false);
  }
};

const subscribeOptions = {
  userVisibleOnly: true,
  applicationServerKey: urlB64ToUint8Array(
    "BLGTBEW8k2eHj-5ZJOlTQTuwlNDusaoSqDoOBUd5vgil2t6XbKrGQP5imGf6k6bys4TSwVgtWn9HRJVsBXczy_w"
  ),
};

export const subscribeWebPush = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) =>
      registration.pushManager.getSubscription().then((subscription) => {
        const isSubscribed = !(subscription === null);

        if (isSubscribed) {
          const endpoint = subscription.endpoint;
          const key = subscription.getKey("p256dh");
          const auth = subscription.getKey("auth");
          sendSubscriptionToServer(endpoint, key, auth);
        } else {
          registration.pushManager
            .subscribe(subscribeOptions)
            .then((subscriptionNew) => {
              const endpoint = subscriptionNew.endpoint;
              const key = subscriptionNew.getKey("p256dh");
              const auth = subscriptionNew.getKey("auth");
              sendSubscriptionToServer(endpoint, key, auth);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
    );
  }
};
