import React, { useContext } from "react";
import { removeStorage } from "../generic/storage";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import NotificationContext from "../../context/notificationsContext";
import { LoginLocation } from "@locations";

//03368445146

export const CleanApp = () => {
  removeStorage("token");
  removeStorage("webpushtoken");
  removeStorage("menus");
  removeStorage("nom_completo_pessoa");
  removeStorage("vlr_identificacao_unica");
  removeStorage("cod_usuario");
  removeStorage("cod_usuario_representacao");
  removeStorage("num_cpf_pessoa");
  removeStorage("num_telefone");
  removeStorage("cod_pessoa");
  removeStorage("funcionalidades");
  removeStorage("cod_pessoa_juridica");
  removeStorage("cod_pessoa_titular");
  removeStorage("siape_titular");
  removeStorage("sgl_posto_graduacao_titular");
  removeStorage("nom_titular");
  removeStorage("cod_posto_graduacao_titular");
  removeStorage("nom_guerra");
  removeStorage("cod_posto_graduacao");
  removeStorage("cod_quadro");
  removeStorage("sgl_posto_graduacao");
  removeStorage("imageAvatar_crypto");
  removeStorage("dados_crypto");
  removeStorage("qrcode_crypto");
  removeStorage("sgl_posto_graduacao");

  const auth = useContext(AuthContext);
  const [dados, setDados] = useContext(NotificationContext);

  setDados({
    ...dados,
    totalExecutouUmaVez: false,
    mensagemExecutouUmaVez: false,
    total: 0,
    mensagens: [],
    mensagensNaoLidas: [],
    totalNaoLidas: 0
  });
  auth.alteraAutenticacao(false, null);
};

const Signout = props => {
  CleanApp();

  return <Redirect to={LoginLocation.path} />;
};

export default Signout;
